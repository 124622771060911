<template>
  <h1>Register Generators</h1>
  <div class="card shadow-3 p-3">
    <form class="formgrid grid">
      <div class="field col-12 md:col-6">
        <label for="firstname6">Firstname</label>
        <InputText
          id="firstname6"
          type="text"
          class="w-full"
        />
      </div>
      <div class="field col-12 md:col-6">
        <label for="lastname6">Lastname</label>
        <InputText
          id="lastname6"
          type="text"
          class="w-full"
        />
      </div>
    </form>
  </div>
  <h1>Register Licenses</h1>
  <div class="card shadow-3 p-3">
    <form class="formgrid grid">
      <div class="field col-12 md:col-6">
        <label for="firstname6">Firstname</label>
        <InputText
          id="firstname6"
          type="text"
          class="w-full"
        />
      </div>
      <div class="field col-12 md:col-6">
        <label for="lastname6">Lastname</label>
        <InputText
          id="lastname6"
          type="text"
          class="w-full"
        />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "Licenses",
}
</script>
